import React, { useEffect, useState, useRef } from "react";
import Index from "../../../layout";
import IndexNotes from "../../../../../assets/icons/sticky-notes.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { viewTeacherAttendenceAction } from "../../../../../redux/actions/TeacherPortal/attendanceAction";
import { useDispatch, useSelector } from "react-redux";
import view from "../../../../../assets/icons/view.png";
import { useHistory } from "react-router-dom";
import Attendance from "../../../../../assets/icons/attandence.png";
import StudentIcon from "../../../../../assets/icons/student.png";
import { Menu } from "primereact/menu";
import MessageIcon from "../../../../../assets/icons/mail 1.png";
import Dropblack from "../../../../../assets/icons/dropblack.png";
import { viewTeacherStudentAction } from "../../../../../redux/actions/TeacherPortal/studentAction";
import CardHeader from "../../../../../pages/CardHeader";
import { firstLetterToUppercase } from "../../../../../utils/regex";
import { showToast } from "../../../../../utils/showToast";
import { getSearchedData } from "../../../../../utils/commonFunctions";
import InputComponent from "../../../../../components/Fields/InputField";
import moment from "moment";

const TeacherStudentContentList = () => {
    const dispatch = useDispatch();
    const [selectedRow, setSelectedRow] = useState([]);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        dispatch(viewTeacherStudentAction());
    }, [dispatch]);
    const StudentList = useSelector((state) => state?.teacherStudent?.getStudentList);

    const history = useHistory();

    const ActionTemplate = (r) => {
        return <img src={view} width="25px" alt="" onClick={() => history.push(`/teacher/students/student-detail/${r?.id}`)} />;
    };
    const ViewMessaging = useRef();
    const messageItems = [
        {
            label: "New Email",
            key: 1,
            command: () => {
                history.push("/teacher/message");
            },
        },
        {
            label: "Email Selected Students",
            key: 3,
            command: () => {
                if (selectedRow?.length) {
                    history.push({
                        pathname: "/teacher/message",
                        state: { role: "parent", items: selectedRow },
                    });
                } else {
                    showToast(["Please Select Atleast One Student"], "error");
                }
            },
        },
        {
            label: "Message History",
            key: 1,
            command: () => {
                history.push("/teacher/message-history");
            },
        },
    ];

    const keys = ["name", "school_name", "student_instruments", "grade", "skill_level"];

    const Lastlesson = (r) => {
        return (
            <>
                {r.last_lesson ? moment(r.last_lesson).format("MMMM-DD-YYYY") : "--------------"} {r.last_lesson_time}
            </>
        );
    };

    const Nextlesson = (r) => {
        return (
            <>
                {r.next_lesson ? moment(r.next_lesson).format("MMMM-DD-YYYY") : "--------------"} {r.next_lesson_time}
            </>
        );
    };

    return (
        <>
            <Index>
                <div className="p-4">
                    <CardHeader icon={StudentIcon} heading="Students" />
                    <div className="table-top-header border-round-top-xl  bg-gray-light p-3">
                        <div className="flex justify-content-between">
                            <div className="flex justify-content-between">
                                <div
                                    className="flex align-items-center open-sans font-semibold text-xs ml-5 cursor-pointer"
                                    onClick={(event) => ViewMessaging.current.toggle(event)}
                                >
                                    <img src={MessageIcon} alt="" className="bg-white p-2 border-round-lg" width={30} />
                                    &nbsp; Messaging &nbsp; <img src={Dropblack} alt="" className="" width={15} />
                                </div>
                                <Menu model={messageItems} popup ref={ViewMessaging} className={" view-menu "} />
                            </div>
                            {setKeyword ? (
                                <div className="p-input-icon-left mx-2">
                                    <i className="pi pi-search z-3" />
                                    <InputComponent value={keyword} inputClass="pl-5" onChange={(e) => setKeyword(e.target.value)} placeholder="Search" />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="shadow-sm border-round-lg overflow-hidden">
                        <DataTable
                            value={getSearchedData(StudentList, keyword, keys)}
                            tableStyle={{ minWidth: "100%" }}
                            selection={selectedRow}
                            onSelectionChange={(e) => setSelectedRow(e.value)}
                            paginator
                            stripedRows
                            responsiveLayout="scroll"
                            paginatorTemplate=" FirstPageLink  PageLinks  LastPageLink RowsPerPageDropdown "
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            className="text-center shadow-1 dynamicdata"
                            style={{ borderRadius: "10px" }}
                            selectionMode="checkbox"
                            emptyMessage="No Student found."
                            filterDisplay="menu"
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                            <Column field="name" header="Name"></Column>
                            <Column field="email" body={(r) => r.email ?? r?.parent_users_id?.email} header="Email"></Column>
                            <Column field="school_name" body={(r) => r.school_name ?? "-"} header="School Name"></Column>
                            <Column field="student_instruments" header="Instruments"></Column>
                            <Column field="grade" body={(r) => (r?.grade ? r?.grade : "-")} header="Grade"></Column>
                            <Column
                                field="skill_level"
                                body={(r) => (r?.skill_level ? firstLetterToUppercase(r?.skill_level) : "-")}
                                header="Skill Level"
                            ></Column>
                            <Column field="parent_users_id.name" header="Family"></Column>
                            <Column field="lastLesson" header="Last Lesson" body={Lastlesson}></Column>
                            <Column field="nextlesson" header="Next Lesson" body={Nextlesson}></Column>

                            <Column header="Actions" body={ActionTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </Index>
        </>
    );
};
export default TeacherStudentContentList;
