import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import _ from "lodash";
import { useEffect, useState } from "react";
import { formatDate } from "../utils/commonFunctions";

export function InputComponent1({ label, name, errorMessage, extraClassName, required, inputClass, labelClassName, onChange, data, value, ...props }) {
    return (
        <div className={`relative ${extraClassName}`}>
            {label ? (
                <label htmlFor={name} className={` text-base m-0 font-semibold p-0 text-dark open-sans form-label ${labelClassName}`}>
                    {label} {required && <span className="p-error">*</span>}
                </label>
            ) : (
                <div className="pt-4"></div>
            )}
            <InputText
                id={name}
                name={name}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value })}
                value={data?.[name] || value}
                className={`w-full border-1 border-round-lg border-400 overflow-hidden ${inputClass} ${errorMessage ? "p-invalid" : null}`}
                {...props}
            />
            {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
        </div>
    );
}
export function DropDownComponent({ label, name, errorMessage, extraClassName, required, inputClass, labelClassName, onChange, data, value, ...props }) {
    return (
        <div className={`relative ${extraClassName}`}>
            {label && (
                <label htmlFor={name} className={` text-base m-0 font-semibold p-0 text-dark open-sans form-label ${labelClassName}`}>
                    {label} {required && <span className="p-error">*</span>}
                </label>
            )}
            <div className="d-md-none">
                <Dropdown
                    id={name}
                    name={name}
                    onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.value })}
                    value={data?.[name] || value}
                    className={`w-full border-round-lg border-1 border-400 overflow-hidden ${inputClass}`}
                    {...props}
                />
                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
            <div className="d-none d-md-block">
                <Dropdown
                    id={name}
                    name={name}
                    onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.value })}
                    value={data?.[name] || value}
                    filter
                    className={`w-full border-round-lg border-1 border-400 overflow-hidden ${inputClass}`}
                    {...props}
                />
                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
        </div>
    );
}
export function MultiSelectComponent({ label, name, errorMessage, extraClassName, onChange, data, value, labelClassName, required, inputClass, ...props }) {
    return (
        <div className={`relative ${extraClassName}`}>
            <label htmlFor={name} className={`absolute text-xs text-500 z-3 open-sans bg-white-color form-labe z-3 top-0  bg-white ${labelClassName}`}>
                {label} {required && <span className="p-error">*</span>}
            </label>
            <MultiSelect
                id={name}
                name={name}
                value={data?.[name] || value}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.value })}
                className={`w-full border-400 ${inputClass} ${errorMessage ? "p-invalid" : null}`}
                {...props}
            />
            {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
        </div>
    );
}

export const CustomDropdownComponent = ({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    inputClass,
    labelClassName,
    sublabel,
    onChange,
    data,
    value,
    ...props
}) => {
    return (
        <>
            <div className={`relative ${extraClassName}`}>
                {label && (
                    <label
                        htmlFor={name}
                        className={`text-base font-semibold p-0 text-dark open-sans form-label  ${labelClassName} ${sublabel ? "mb-0" : "mb-2"}`}
                    >
                        {label} {required && <span className="p-error">*</span>}
                    </label>
                )}
                {sublabel && (
                    <>
                        <p className="text-xs text-main ">{sublabel}</p>
                    </>
                )}

                <Dropdown
                    id={name}
                    name={name}
                    filter
                    onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.value })}
                    value={data?.[name] || value}
                    className={`w-full mt-1 border-round-lg border-1 border-400 overflow-hidden ${inputClass}`}
                    {...props}
                />
                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
        </>
    );
};

export const CustomDropdownAutoCompleteComponent = ({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    inputClass,
    labelClassName,
    sublabel,
    onChange,
    data,
    value,
    options,
    suggestions = [],
    forceSelection = true,
    ...props
}) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [filteredItems, setFilteredItems] = useState(null);

    const searchItems = (event) => {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo purposes we filter at client side
        let query = event.query;
        let _filteredItems = [];
        for (let i = 0; i < options.length; i++) {
            let item = options[i];
            if (typeof item.name === "string") {
                if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                    _filteredItems.push(item);
                }
            } else if (typeof item.name === "number") {
                if (item.name.toString().indexOf(query) === 0) {
                    _filteredItems.push(item);
                }
            }
        }
        setFilteredItems(_filteredItems);
    };

    useEffect(() => {
        if (value && options) {
            let _selected = options.find((item) => item.value === value);
            setSelectedItem(_selected);
        }
    }, [value]);

    useEffect(() => {
        if (selectedItem?.value) {
            onChange({ name, value: selectedItem.value });
        }
    }, [selectedItem]);

    return (
        <>
            <div className={`relative ${extraClassName}`}>
                {label && (
                    <label
                        htmlFor={name}
                        className={`text-base font-semibold p-0 text-dark open-sans form-label  ${labelClassName} ${sublabel ? "mb-0" : "mb-3"}`}
                    >
                        {label} {required && <span className="p-error">*</span>}
                    </label>
                )}
                {sublabel && (
                    <>
                        <p className="text-xs text-main ">{sublabel}</p>
                    </>
                )}
                <AutoComplete
                    value={selectedItem}
                    suggestions={filteredItems}
                    completeMethod={searchItems}
                    virtualScrollerOptions={{ itemSize: 38 }}
                    field="name"
                    dropdown
                    onChange={(e) => setSelectedItem(e.value)}
                    className={`w-full mt-1 border-round-lg border-1 border-400 overflow-hidden ${inputClass}`}
                    {...props}
                />

                {/* <Dropdown
                    id={name}
                    name={name}
                    filter
                    onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.value })}
                    value={data?.[name] || value}
                    className={`w-full mt-1 border-round-lg border-1 border-400 overflow-hidden ${inputClass}`}
                    {...props}
                /> */}
                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
        </>
    );
};

export const RadioButtonComponent = ({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    labelClassName,
    options,
    value,
    data,
    onChange,
    optionLabel,
    ...props
}) => {
    return (
        <div className={`flex radio-btn align-items-center justify-content-left ml-2 ${extraClassName}`}>
            <div className={`flex align-items-center mr-6`}>
                <RadioButton value={value} onChange={(e) => onChange && onChange({ name, value: e.target.value, ...e })} id={name} name={name} {...props} />
                &nbsp;&nbsp;
                <label htmlFor={name}>{optionLabel ? value[optionLabel] : label}</label>
            </div>
            {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
            {/* &nbsp;&nbsp;
            <label htmlFor={name} className={`m-0 ${labelClassName}`}>
                {label} {required && <span className="p-error">*</span>}
            </label> */}
        </div>
    );
};
export const CheckboxComponent = ({ label, name, errorMessage, extraClassName, required, inputClass, labelClassName, onChange, value, data, ...props }) => {
    return (
        <div className={`flex align-items-center ${extraClassName}`}>
            <div className="">
                <Checkbox
                    id={name}
                    name={name}
                    value={value || data?.[name]}
                    className={` ${inputClass} ${errorMessage ? "p-invalid" : null}`}
                    onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: !e.target.value })}
                    {...props}
                />

                {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
            </div>
            {label && (
                <label htmlFor={name} className={`ml-2 font-normal mb-0 ${labelClassName}`}>
                    {label} {required && <span className="p-error">*</span>}
                </label>
            )}
        </div>
    );
};
export const NumberComponent = ({
    label,
    name,
    errorMessage,
    extraClassName,
    labelClassName,
    required,
    size,
    inputClass,
    inputId,
    dollar,
    min,
    dollarClass,
    data,
    onChange,
    value,
    ...props
}) => {
    return (
        <div className={`relative ${extraClassName}`}>
            <label htmlFor={inputId} className={` text-base m-0 font-semibold p-0 text-dark open-sans form-label ${labelClassName}`}>
                {label} {required && <span className="p-error">*</span>}
            </label>

            <InputNumber
                id={name}
                name={name}
                onChange={(e) => onChange && onChange({ ...e, [name]: e.target.name, value: e.value })}
                value={data?.[name] || value}
                className={`overflow-hidden w-full ${dollar || min ? "" : "border-round-lg"}  border-400 border-1 ${
                    dollar ? `border-round-right-lg border-noround ${dollarClass}` : min ? "border-round-left-lg border-noround" : inputClass
                } ${errorMessage ? "p-invalid" : null}`}
                {...props}
            />
            {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
        </div>
    );
};
export default function CustomCalendar({
    label,
    name,
    errorMessage,
    onChange,
    value,
    labelclassName,
    extraClassName,
    required,
    inputClass,
    size,
    data,
    showIcon,
    timeOnly,
    ...props
}) {
    return (
        <div className={size}>
            <div className={`relative  ${extraClassName}`}>
                <label htmlFor={name} className={`absolute text-xs text-500 open-sans bg-white form-label z-1 ${labelclassName}`}>
                    {label} {required && <span className="p-error">*</span>}
                </label>
                <div className="">
                    <Calendar
                        id={name}
                        name={name}
                        {...props}
                        timeOnly={timeOnly}
                        value={timeOnly ? value : formatDate(data?.[name] || value)}
                        showWeek={false}
                        onChange={(e) => onChange && onChange({ ...e, name, value: e.value })}
                        className={`w-full border-1 border-round-lg border-400 overflow-hidden ${inputClass} ${errorMessage ? "" : null}`}
                        showIcon
                    ></Calendar>
                    {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
                </div>
            </div>
        </div>
    );
}
export function CustomCalendarTime({
    label,
    name,
    errorMessage,
    onChange,
    value,
    labelclassName,
    extraClassName,
    required,
    inputClass,
    size,
    data,
    showIcon,
    ...props
}) {
    const handleChange = (e) => {
        let currentMin = e.value.getMinutes();
        let startTime = e.value;
        if (currentMin % 5 !== 0) {
            startTime = new Date(e.value.getTime() + (5 - (currentMin % 5)) * 60000);
        }
        if (onChange) {
            onChange({ name, value: startTime });
        }
    };

    return (
        <div className={size}>
            <div className={`relative  ${extraClassName}`}>
                <label htmlFor={name} className={`absolute text-xs text-500 open-sans bg-white form-label z-1 ${labelclassName}`}>
                    {label} {required && <span className="p-error">*</span>}
                </label>
                <div className="">
                    <Calendar
                        id={name}
                        name={name}
                        {...props}
                        showWeek={false}
                        timeOnly
                        onChange={handleChange}
                        value={data?.[name] || value}
                        className={`w-full border-1 border-round-lg border-400 overflow-hidden ${inputClass} ${errorMessage ? "" : null}`}
                        showIcon
                        icon="pi pi-clock"
                    ></Calendar>
                    {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
                </div>
            </div>
        </div>
    );
}

export function PhoneNumberComponent({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    size,
    inputClass,
    labelClassName,
    inputClassName,
    onChange,
    ...props
}) {
    const PhoneInputChange = (value, data) => {
        // setPhoneNumber(value);
        if (value) {
            const countryCode = `+${data.dialCode}`;
            const phone = value.slice(data.dialCode.length);
            // setPhoneNumber({ countryCode, phone });
        }
    };
    const handleChange = (value, data) => {
        if (value) {
            const countryCode = `+${data.dialCode}`;
            const phone = value.slice(data.dialCode.length);

            if (onChange) {
                onChange({ name, value: countryCode + phone });
            }
        } else {
            if (onChange) {
                onChange({ name, countryCode: "", phone: "", value: "" });
            }
        }
    };
    return (
        <div className={size}>
            <div className={`relative mb-2 ${extraClassName}`}>
                {label ? (
                    <label htmlFor={name} className={`text-base m-0 font-semibold p-0 text-dark open-sans form-label ${labelClassName}`}>
                        {label} {required && <span className="p-error">*</span>}
                    </label>
                ) : null}

                <PhoneInput
                    className={`w-full number-input ${inputClassName} ${errorMessage ? "p-invalid" : null}`}
                    onChange={(e, data) => handleChange(e, data)}
                    inputClass={inputClass}
                    country={"us"}
                    {...props}
                />

                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
        </div>
    );
}
