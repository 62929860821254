import Constants from "../../../services/constant";
import { api } from "../../../services/api";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { showToast } from "../../../utils/showToast";
import moment from "moment";
import types from "../../types";

const onGetSchedulesAction = (next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.GET_ALL_SCHEDULES);
    if (res.success) {
        next(res.data);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
const onGetScheduleAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.GET_SCHEDULE + id);
    if (res.success) {
        next(res.data);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
const onAddScheduleAction = (schedule, classData, next) => async (dispatch) => {
    let payload = {
        school_id: schedule?.school?.id,
        day: schedule?.day,
        start_date: moment(schedule?.startDate).format("DD-MM-yyyy"),
        end_date: moment(schedule?.endDate).format("DD-MM-yyyy"),
        start_time: schedule?.startTime,
        end_time: schedule?.endTime,
        interval: schedule?.interval,
    };

    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.ADD_SCHEDULE, payload);
    if (res.success) {
        await dispatch(
            onAddClassAction({ ...classData, scheduleId: res?.data?.id }, () => {
                next(res?.data);
            })
        );
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};

const onEditScheduleAction = (schedule, next) => async (dispatch) => {
    let payload = {
        id: schedule?.id,
        school_id: schedule?.school?.id,
        day: schedule?.day,
        start_date: moment(schedule?.startDate).format("DD-MM-yyyy"),
        end_date: moment(schedule?.endDate).format("DD-MM-yyyy"),
        start_time: schedule?.startTime,
        end_time: schedule?.endTime,
        interval: schedule?.interval,
    };

    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.EDIT_SCHEDULE, payload);
    if (res.success) {
        next(res.data);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
const onAddClassAction = (classData, next) => async (dispatch) => {
    let payload = {
        class_schedule_id: classData?.scheduleId,
        class_title: classData?.title,
        class_type: classData?.classType,
        calendar_category_id: classData?.category?.id,
        current_attendees: classData?.students.map((item) => item?.id),
        description: classData?.description,
        duration: classData?.duration,
    };

    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.ADD_SCHEDULE_CLASS, payload);
    if (res.success) {
        next(res.data);
    } else {
        next(res.data);

        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
const onEditClassAction = (classData, next) => async (dispatch) => {
    let payload = {
        id: classData?.id,
        class_schedule_id: classData?.scheduleId,
        class_title: classData?.title,
        class_type: classData?.classType,
        calendar_category_id: classData?.category?.id,
        current_attendees: classData?.students.map((item) => item?.id),
        description: classData?.description,
        duration: classData?.duration,
        date: moment(classData?.date).format("DD-MM-yyyy"),
    };

    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.EDIT_SCHEDULE_CLASS, payload);
    if (res.success) {
        next(res.data);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
const onDeleteClassAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.DELETE_SCHEDULE_CLASS + id);
    if (res.success) {
        next(res.data);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};

const onGetEventsAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.GET_SCHEDULE_EVENTS + id);
    if (res.success) {
        next(res.data);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
const onGetEventClassesAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.GET_SCHEDULE_EVENTS_CLASSES, { class_schedule_id: id });
    if (res.success) {
        next(res.data);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};

const onAddClassEventAction = (data, next) => async (dispatch) => {
    // if event id is present in data, it means we are updateing this event , otherwise it will create a new event
    let payload = {
        event_id: data?.event_id,
        class_id: data?.class_id,
        date: data?.date,
        time: data.time,
    };
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.ADD_SCHEDULE_EVENTS_CLASSES, payload);
    if (res.success) {
        next(res.data);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};

const onGetEventAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.GET_SCHEDULE_EVENT + id);
    if (res.success) {
        dispatch({
            type: types.EVENT_DETAILS,
            payload: { ...res.data, event_id: id },
        });
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
const onDeleteEventAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.DELETE_SCHEDULE_EVENT + id);
    if (res.success) {
        next(res.data);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
export {
    onAddScheduleAction,
    onGetScheduleAction,
    onAddClassAction,
    onEditClassAction,
    onDeleteClassAction,
    onEditScheduleAction,
    onGetSchedulesAction,
    onGetEventsAction,
    onGetEventClassesAction,
    onAddClassEventAction,
    onGetEventAction,
    onDeleteEventAction,
};
