import React, { useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Chip } from "primereact/chip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Layout from "../../../layout";
import DropDownComponent from "../../../../../components/Fields/CustomDropdown";
import CustomCalendar, { CustomCalendarTime } from "../../../../../components/Fields/CustomCalendar";
import InputTextAreaComponent from "../../../../../components/Fields/CustomInputTextArea";
import ButtonComponent from "../../../../../components/Fields/Button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { viewTeacherStudentAction } from "../../../../../redux/actions/TeacherPortal/studentAction";
import { getTeacherSchoolsAction } from "../../../../../redux/actions/TeacherPortal/teacherAction";
import { getCategoryAction } from "../../../../../redux/actions/TeacherPortal/eventsAction";
import { useEffect } from "react";
import MultiSelectComponent from "../../../../../components/Fields/CustomMultiSelect";
import {
    onAddScheduleAction,
    onGetScheduleAction,
    onAddClassAction,
    onEditClassAction,
    onDeleteClassAction,
    onEditScheduleAction,
} from "../../../../../redux/actions/TeacherPortal/scheduleAction";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import viewIcon from "../../../../../assets/icons/eyegreen.png";
import deleteIcon from "../../../../../assets/icons/delete.png";
import editIcon from "../../../../../assets/icons/edit.png";
import moment from "moment";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import scheduledIcon from "../../../../../assets/icons/scheduled.png";
import { getStudentName } from "../../../../../utils/commonFunctions";
export default function AddSchedule() {
    const history = useHistory();
    const { id } = useParams();

    const schoolDropdown = useSelector((state) => state?.teacher?.getSchools);
    const StudentList = useSelector((state) => state?.teacherStudent?.getStudentList);
    const category = useSelector((state) => state?.teacherEvents?.getCategories);
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const titles = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    const classTypes = [
        { name: "Small Group lesson During school", value: "during_school" },
        { name: "Small Group lesson After school", value: "after_school" },
        { name: "Semi Private Lesson During School", value: "semi_private_during_school" },
        { name: "Semi Private After school", value: "semi_private" },
    ];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(viewTeacherStudentAction());
        dispatch(getTeacherSchoolsAction());
        dispatch(getCategoryAction());
    }, [dispatch]);

    const [res, setRes] = useState(null);
    const [usedTitles, setUsedTitles] = useState([]);

    useEffect(() => {
        const getStudents = (data) => {
            let filteredStudents = [];

            data?.current_attendees.forEach((item) =>
                StudentList.find((student) => {
                    if (student.id === item) {
                        filteredStudents.push(student);
                        return true;
                    }
                })
            );

            return filteredStudents;
        };

        if (res && StudentList && category) {
            setData({
                school: res.school_id,
                day: res.day,
                startDate: res.start_date,
                endDate: res.end_date,
                startTime: res.start_time,
                endTime: res.end_time,
                interval: res?.interval,
                is_event_created: res?.is_event_created,
                classes: res.class_schedule_lists.map((item) => ({
                    id: item?.id,
                    title: item?.class_title,
                    category: category.find((cat) => cat.id == item?.calendar_category_id),
                    classType: item?.class_type,
                    students: getStudents(item),
                    description: item?.description,
                    duration: item?.duration,
                })),
            });
            let _usedTitles = res.class_schedule_lists.map((item) => item?.class_title);
            setUsedTitles(_usedTitles);
        }
    }, [res, StudentList.length, category.length]);

    useEffect(() => {
        getSchedule(id);
    }, [id]);

    const getSchedule = (id) => {
        if (id) {
            dispatch(
                onGetScheduleAction(id, (e) => {
                    setRes(e);
                })
            );
        }
    };

    const [data, setData] = useState({ school: "", day: "", startDate: "", endDate: "", startTime: "", endTime: "", interval: "", classes: [] });
    const [visible, setVisible] = useState(false);
    const [viewVisible, setViewVisible] = useState(false);
    const [isEditing, setISEditing] = useState(false);

    const [schedule, setSchedule] = useState({
        school: "",
        day: "",
        startDate: new Date(),
        endDate: new Date(moment().add(1, "months")),
        startTime: "11:00",
        endTime: "16:00",
        interval: 30,
        date: new Date(),
    });
    const [classData, setClassData] = useState({ title: "", category: "", classType: "", duration: 30, students: [], description: "" });

    const availableTitles = useMemo(() => {
        let result = [];
        result = titles.filter((item) => !usedTitles.includes(item));
        classData?.title && result.unshift(classData.title);
        return result;
    }, [usedTitles, classData]);

    const handleChange = (e) => {
        let { name, value } = e.target;
        setSchedule((prev) => ({ ...prev, [name]: value }));
        let _errors = { ...errors };
        if (_errors[name]) {
            delete _errors[name];
        }
        setErrors(_errors);
    };
    const handleClassChange = (e) => {
        let { name, value } = e.target;
        setClassData((prev) => ({ ...prev, [name]: value }));

        let _errors = { ...classErrors };
        if (_errors[name]) {
            delete _errors[name];
        }
        setClassErrors(_errors);
    };
    const onAddSchedule = () => {
        if (validateSchedule() && validateClass()) {
            dispatch(
                onAddScheduleAction(schedule, classData, (e) => {
                    history.push(`/teacher/schedule/edit/${e.id}`);
                })
            );
        }
    };

    const availableDays = useMemo(() => {
        let _days = days.filter((item) => !schedule?.school?.days?.includes(item));
        if (data?.day) {
            _days.push(data?.day);
        }
        return _days;
    }, [schedule?.school, data]);

    const studentsDropdown = useMemo(() => {
        if (schedule?.school) {
            return StudentList.filter((item) => item?.school_id === schedule?.school?.id);
        } else if (data?.school) {
            return StudentList.filter((item) => item?.school_id === data?.school);
        } else {
            return StudentList;
        }
    }, [StudentList, schedule?.school, data]);

    const ActionTemplate = (r) => {
        return (
            <>
                <img
                    src={editIcon}
                    width="25px"
                    className="mx-2 cursor-pointer"
                    onClick={() => {
                        setClassData(r);
                        setVisible(true);
                    }}
                />
                <img src={deleteIcon} onClick={(e) => onClickDelete(e, r.id)} width="25px" className="mx-2 cursor-pointer" />
                <img
                    src={viewIcon}
                    onClick={() => {
                        setClassData(r);
                        setViewVisible(true);
                    }}
                    width="25px"
                    className="mx-2 cursor-pointer"
                />
            </>
        );
    };

    const studentTemplate = (r) => {
        return r?.students.map((item) => <Chip className="mr-1 mt-1" label={getStudentName(item)} />);
    };
    const categoryTemplate = (r) => {
        return r?.category?.name;
    };

    const onAddClassSchedule = () => {
        if (validateClass()) {
            dispatch(
                onAddClassAction({ ...classData, scheduleId: id }, (e) => {
                    getSchedule(id);
                    onHide();
                })
            );
        }
    };

    const onEditClassSchedule = () => {
        dispatch(
            onEditClassAction({ ...classData, date: schedule?.date }, (e) => {
                getSchedule(id);
                onHide();
            })
        );
    };

    const onHide = () => {
        setVisible(false);
        setClassData({ title: "", category: "", classType: "", students: [], description: "" });
    };
    const onViewHide = () => {
        setViewVisible(false);
        setClassData({ title: "", category: "", classType: "", students: [], description: "" });
    };
    const onClickDelete = (event, classId) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to delete?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                dispatch(onDeleteClassAction(classId, () => getSchedule(id)));
            },
        });
    };

    const onClickEdit = () => {
        setISEditing(true);
        setSchedule({
            id,
            school: schoolDropdown.find((item) => item.id == data.school),
            day: data?.day,
            startDate: new Date(data?.startDate),
            endDate: new Date(data?.endDate),
            startTime: data?.startTime,
            endTime: data?.endTime,
            interval: data?.interval,
        });
    };

    const onCancelEdit = () => {
        setISEditing(false);
        setSchedule({
            school: "",
            day: "",
            startDate: "",
            endDate: "",
            startTime: "",
            endTime: "",
            interval: "",
        });
    };

    const onEditSchedule = () => {
        if (validateSchedule()) {
            dispatch(
                onEditScheduleAction(schedule, (e) => {
                    getSchedule(id);
                    onCancelEdit();
                })
            );
        }
    };
    const intervals = [
        {
            name: "5 Minute",
            value: 5,
        },
        {
            name: "15 Minute",
            value: 15,
        },
        {
            name: "30 Minute",
            value: 30,
        },
        {
            name: "45 Minute",
            value: 45,
        },
        {
            name: "60 Minute",
            value: 60,
        },
    ];

    const [errors, setErrors] = useState({});
    const [classErrors, setClassErrors] = useState({});

    const validateSchedule = () => {
        let _errors = { ...errors };
        let valid = true;
        if (!schedule?.school) {
            _errors.school = "School is required!";
            valid = false;
        } else {
            if (_errors?.name) {
                delete _errors.name;
            }
        }
        if (!schedule?.day) {
            _errors.day = "Day is required!";
            valid = false;
        } else {
            if (_errors?.day) {
                delete _errors.day;
            }
        }
        setErrors(_errors);
        return valid;
    };

    const validateClass = () => {
        let _errors = { ...classErrors };
        let valid = true;
        if (!classData?.title) {
            _errors.title = "Title is required!";
            valid = false;
        } else {
            if (_errors?.title) {
                delete _errors.title;
            }
        }
        if (!classData?.category) {
            _errors.category = "Category is required!";
            valid = false;
        } else {
            if (_errors?.category) {
                delete _errors.category;
            }
        }
        if (!classData?.classType) {
            _errors.classType = "Class Type is required!";
            valid = false;
        } else {
            if (_errors?.classType) {
                delete _errors.classType;
            }
        }
        if (!classData?.description) {
            _errors.description = "Description is required!";
            valid = false;
        } else {
            if (_errors?.description) {
                delete _errors.description;
            }
        }
        setClassErrors(_errors);
        return valid;
    };

    const addClasstemplate = () => {
        return (
            <div className="grid my-3">
                <DropDownComponent
                    label="Class Title"
                    name="title"
                    extraClassName="mb-2 md:col-6"
                    inputClass="w-full border-1  border-round-lg border-400"
                    placeholder=""
                    required
                    options={availableTitles}
                    value={classData?.title}
                    onChange={handleClassChange}
                    errorMessage={classErrors?.title}
                />
                <DropDownComponent
                    label="Select Category"
                    name="category"
                    extraClassName="mb-2 md:col-6"
                    inputClass="w-full border-1  border-round-lg border-400"
                    placeholder=""
                    required
                    options={category}
                    optionLabel="name"
                    value={classData?.category}
                    onChange={handleClassChange}
                    errorMessage={classErrors?.category}
                />
                <DropDownComponent
                    label="Select Class Type"
                    name="classType"
                    extraClassName="mb-2 md:col-6"
                    inputClass="w-full border-1  border-round-lg border-400"
                    placeholder=""
                    required
                    options={classTypes}
                    optionLabel="name"
                    value={classData?.classType}
                    onChange={handleClassChange}
                    errorMessage={classErrors?.classType}
                />
                <DropDownComponent
                    label="Select Duration"
                    name="duration"
                    extraClassName="mb-2 md:col-6"
                    inputClass="w-full border-1  border-round-lg border-400"
                    required
                    optionLabel="name"
                    options={intervals}
                    value={classData?.duration}
                    onChange={handleClassChange}
                    filter={false}
                />
                <MultiSelectComponent
                    label="Add Students"
                    name="students"
                    extraClassName="mb-2 md:col-12"
                    inputClass="w-full border-1  border-round-lg border-400"
                    options={studentsDropdown}
                    optionLabel={getStudentName}
                    value={classData?.students}
                    onChange={handleClassChange}
                />
                <InputTextAreaComponent
                    label="Description"
                    name="description"
                    extraClassName="mb-2 md:col-12"
                    inputClass="w-full border-1  border-round-lg border-400"
                    placeholder=""
                    required
                    value={classData?.description}
                    onChange={handleClassChange}
                    errorMessage={classErrors?.description}
                />
                {classData?.id && (
                    <CustomCalendar
                        size="w-50"
                        extraClassName="mb-2 md:col-12"
                        onChange={handleChange}
                        name="date"
                        label="Date"
                        value={schedule?.date}
                        helperText={"Adjust the date to set when the changes take effect"}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <ConfirmPopup />

            <Dialog
                draggable={false}
                resizable={false}
                header={classData?.id ? "Edit Class Schedule" : "Add Class Schedule"}
                visible={visible}
                style={{ width: "50vw" }}
                onHide={onHide}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                {addClasstemplate()}
                <div className="text-center">
                    {classData?.id ? (
                        <ButtonComponent className="mx-2" onClick={onEditClassSchedule} label="Update" />
                    ) : (
                        <ButtonComponent className="mx-2" onClick={onAddClassSchedule} label="Save" />
                    )}
                    <ButtonComponent className="mx-2" onClick={onHide} severity="secondary" label="Cancel" />
                </div>
            </Dialog>

            <Dialog
                draggable={false}
                resizable={false}
                header={classTypes.find((item) => item.value === classData.classType)?.name}
                visible={viewVisible}
                style={{ width: "50vw" }}
                onHide={onViewHide}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className="grid my-3">
                    <div className="col-12 grid ">
                        <div className="font-semibold col-2">Class Title</div>
                        <div className="p col-10">{classData?.title}</div>
                    </div>
                    <div className="col-12 grid ">
                        <div className="font-semibold col-2">Category</div>
                        <div className="p col-10">{classData?.category?.name}</div>
                    </div>
                    <div className="col-12 grid ">
                        <div className="font-semibold col-2">Students</div>
                        <div className="p col-10">{studentTemplate(classData)}</div>
                    </div>
                    <div className="col-12 grid ">
                        <div className="font-semibold col-2">Description</div>
                        <div className="p col-10">{classData?.description}</div>
                    </div>
                </div>
            </Dialog>

            <Layout>
                <div className="p-5">
                    <div className="flex justify-content-between">
                        <span className=" flex text-main text-2xl open-sans font-semibold">Class Schedule</span>

                        {id && !isEditing && (
                            <div>
                                <img
                                    src={scheduledIcon}
                                    width="25px"
                                    height="25px"
                                    className="cursor-pointer mx-4"
                                    onClick={() => history.push(`/teacher/schedule/setup/${id}`)}
                                />
                                <img src={editIcon} width="25px" height="25px" className="cursor-pointer" onClick={onClickEdit} />
                            </div>
                        )}
                    </div>

                    {id && !isEditing ? (
                        <div className="grid my-3">
                            <div className="col-6 flex ">
                                <div className="h5 w-6">School</div>
                                <div className="p">{schoolDropdown.find((item) => item.id === data.school)?.name}</div>
                            </div>
                            <div className="col-6 flex ">
                                <div className="h5 w-6">Day</div>
                                <div className="p">{data?.day}</div>
                            </div>
                            <div className="col-6 flex ">
                                <div className="h5 w-6">Start Date</div>
                                <div className="p">{data?.startDate}</div>
                            </div>
                            <div className="col-6 flex ">
                                <div className="h5 w-6">End Date</div>
                                <div className="p">{data?.endDate}</div>
                            </div>{" "}
                            <div className="col-6 flex ">
                                <div className="h5 w-6">Start Time</div>
                                <div className="p">{moment(data?.startTime, "HH:mm").format("hh:mm A")}</div>
                            </div>
                            <div className="col-6 flex ">
                                <div className="h5 w-6">End Time</div>
                                <div className="p">{moment(data?.endTime, "HH:mm").format("hh:mm A")}</div>
                            </div>
                            {/* <div className="col-6 flex ">
                                <div className="h5 w-6">Interval</div>
                                <div className="p">{data?.interval} Minute</div>
                            </div> */}
                        </div>
                    ) : (
                        <>
                            <div className="grid my-3">
                                <DropDownComponent
                                    label="Select School"
                                    name="school"
                                    extraClassName="mb-2 md:col-6"
                                    inputClass="w-full border-1  border-round-lg border-400"
                                    required
                                    options={schoolDropdown}
                                    optionLabel="name"
                                    value={schedule?.school}
                                    onChange={handleChange}
                                    errorMessage={errors?.school}
                                />
                                <DropDownComponent
                                    label="Select Day"
                                    name="day"
                                    extraClassName="mb-2 md:col-6"
                                    inputClass="w-full border-1  border-round-lg border-400"
                                    placeholder=""
                                    required
                                    options={availableDays}
                                    value={schedule?.day}
                                    onChange={handleChange}
                                    disabled={data?.is_event_created}
                                    errorMessage={errors?.day}
                                />
                                <div className="mb-2 md:col-6">
                                    <CustomCalendar
                                        label="Start Date"
                                        name="startDate"
                                        maxDate={schedule?.endDate}
                                        value={schedule?.startDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-2 md:col-6">
                                    <CustomCalendar
                                        label="End Date"
                                        name="endDate"
                                        minDate={schedule?.startDate}
                                        value={schedule?.endDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-2 md:col-6">
                                    <CustomCalendarTime label="Start Time" name="startTime" value={schedule?.startTime} onChange={handleChange} />
                                </div>
                                <div className="mb-2 md:col-6">
                                    <CustomCalendarTime label="End Time" name="endTime" value={schedule?.endTime} onChange={handleChange} />
                                </div>
                                {/* <DropDownComponent
                                    label="Select Interval"
                                    name="interval"
                                    extraClassName="mb-2 md:col-6"
                                    inputClass="w-full border-1  border-round-lg border-400"
                                    required
                                    optionLabel="name"
                                    options={intervals}
                                    value={schedule?.interval}
                                    onChange={handleChange}
                                    filter={false}
                                /> */}
                            </div>
                            {isEditing && (
                                <div className="text-right">
                                    <ButtonComponent className="mx-2" onClick={onEditSchedule} label="Update" />
                                    <ButtonComponent className="mx-2" onClick={onCancelEdit} severity="secondary" label="Cancel" />
                                </div>
                            )}
                        </>
                    )}

                    <span className=" flex text-main text-2xl open-sans font-semibold">Add Class</span>
                    {id ? (
                        <>
                            <div className="my-3">
                                <DataTable showGridlines className="text-dark online-lesson" value={data?.classes}>
                                    <Column field="title" header="Class Title"></Column>
                                    <Column body={categoryTemplate} header="Category"></Column>
                                    <Column body={studentTemplate} header="Students" style={{ width: "30%" }}></Column>
                                    <Column body={ActionTemplate} header="Action" />
                                </DataTable>
                            </div>
                            <ButtonComponent label="Add Class" onClick={() => setVisible(true)} />
                        </>
                    ) : (
                        addClasstemplate()
                    )}

                    {!id && (
                        <div className="text-center">
                            <ButtonComponent className="mx-2" onClick={onAddSchedule} label="Save" />
                            <ButtonComponent className="mx-2" severity="secondary" label="Cancel" />
                        </div>
                    )}
                </div>
            </Layout>
        </>
    );
}
